<template>
	<tr>
		<td class="pl-3">
			<a v-if="template.deleted_at || template.archived_at" :href="'/template-preview/'+template.id" target="_blank"><b>{{ template.name }}</b></a>
			<a v-else :href="'/templates/'+template.id" target="_blank"><b>{{ template.name }}</b></a>
		</td>
		<td>
			<p class="approved-by">
				<span v-for="company in template.companies" class="company text-center" :class="company.slug">{{ company.slug }}</span>
			</p>
		</td>
		<td class="text-muted">{{ template.gps_id }}</td>
		<td class="text-muted">
			{{ template.view }}<br>
			<small v-if="template.public_links.length">
				<span v-if="getLatestLink(template.public_links)">
					<a :href="'/public/'+getLatestLink(template.public_links).slug" target="_blank">Public Link</a> until {{ getLatestLink(template.public_links).expired_at }}
				</span>
			</small>
		</td>
		<td class="text-muted">{{ template.revision_date }}</td>
		<td v-if="isAdmin" class="pr-3">
			<div class="dropdown">
				<a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					More
				</a>

				<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
					<a class="dropdown-item" :href="'/template-preview/'+template.id" target="_blank">Preview (in-office only)</a>
					<a v-if="!template.deleted_at" class="dropdown-item" :href="'/templates/'+template.id" target="_blank">Show</a>
					<a v-if="!template.deleted_at" class="dropdown-item" :href="'/templates/'+template.id+'/edit'">Edit</a>
					<a v-if="!template.deleted_at" class="dropdown-item" data-toggle="modal" :data-target="'#modal_'+template.view" @click="[searchedName = '', urlErrorMsg = '']" href="">Preview as</a>
					<a v-if="!template.deleted_at" class="dropdown-item" data-toggle="modal" :data-target="'#modal_publish'+template.view" @click="expirationDate=null" href="#">Make Public</a>
					<div v-if="!(template.deleted_at && !template.archived_at)" class="dropdown-divider"></div>
					<a v-if="!template.deleted_at && !template.archived_at" @click.prevent="archiveTemplate(template)" class="dropdown-item text-danger" href="">Archive</a>
					<a v-if="template.deleted_at && template.archived_at" @click.prevent="reactivateTemplate(template)" class="dropdown-item text-danger" href="">Re-Activate</a>
				</div>
			</div>
			
			<!-- Modal Publish Template -->
			<div class="modal fade" :id="'modal_publish'+template.view" tabindex="-1">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">{{ getLatestLink(template.public_links) ? 'Current Public Link' : 'Set expiry date of the link'}}</h5>
							<button type="button" class="close color-primary" data-dismiss="modal">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div v-if="getLatestLink(template.public_links)">
								<p>
									<b>Current public link:</b> <a  :href="'/public/'+getLatestLink(template.public_links).slug" target="_blank">Here</a> <br>
									<b>Expiration date:</b> {{ getLatestLink(template.public_links).expired_at }}
								</p>
							</div>
							<div v-else>
								<p class="small text-muted">
									<b>{{template.name}}, {{template.view}}</b>
									<hr>
								</p>
								<div class="form-group">
									<input v-model="expirationDate" type="date" class="form-control">
								</div>
								<p v-if="expirationDate && !validExiparationDate(expirationDate)" class="text-danger text-muted">* Enter valid date</p>
								<button v-if="validExiparationDate(expirationDate) && !saving" @click="makePublic(expirationDate)" class="btn btn-success pull-right">Save</button>
								<p v-if="saving" class="pull-right">...saving</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Modal Template View as -->
			<div class="modal fade" :id="'modal_'+template.view" tabindex="-1">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Preview as an advisor</h5>
							<button type="button" class="close color-primary" data-dismiss="modal">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div v-if="!loadingUrl">
								<p class="small text-muted">
									<b>{{template.name}}, {{template.view}}</b>
								</p>
								<p class="small text-muted">
									<a @click.prevent="[urlErrorMsg = '', searchedName = '']" class="text-danger" href="">{{ urlErrorMsg }}</a>
								</p>
								<div class="form-group">
									<input v-model="searchedName" type="text" placeholder="Search advisor name ..." class="form-control">
								</div>
								<div v-if="searchedName">
									<div v-if="filteredAdvisors.length">
										<p v-for="advisor in filteredAdvisors">
											<a @click.prevent="viewTemplate(advisor.gdrive_id, template.gps_id, advisor.search)" href="" target="_blank">
												{{ advisor.search }}
											</a>
										</p>
									</div>
									<div v-else>
										<p class="text-muted">There are no matches ... sorry!</p>
									</div>
								</div>
							</div>
							<div v-else>
								<p class="text-center color-primary">Loading ...</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
	export default {
		props: ['template', 'people'],
		data: () => ({
			searchedName: '',
			loadingUrl: false,
			urlErrorMsg: '',
			expirationDate: null,
			hasValidExiparationDate: false,
			saving: false
		}),
		computed: {
			isAdmin() {
				return window.Laravel.user.role === 'admin';
			},
			postData() {
				return {
					'expired_at': this.expirationDate,
					'template_id': this.template.id,
					'user_id': window.Laravel.user.id,
				}
			},
			filteredAdvisors() {
				// Used Lodash because plain js was not working for some reason
				return _.filter(this.people, (person) => {
					return this.searchedName.toLowerCase().split(' ').every((item) => {
						return person.search.toLowerCase().includes(item) ||
								person.gdrive_id.toString().includes(item);
					});
				});

			},
		},
		methods: {
			archiveTemplate(template) {
				axios.get('/api/templates/'+template.id+'/archive/'+window.Laravel.user.id)
					.then((response) => {
						location.reload();
						// console.log(response.data);
					});
			},
			reactivateTemplate(template) {
				axios.get('/api/templates/'+template.id+'/reactivate')
					.then((response) => {
						location.reload();
						// console.log(response.data);
					});
			},
			getLatestLink(publicLinks) {
				let latest = null;
				_.forEach(publicLinks, (linkItem) => {
					if (linkItem.expired_at > moment().format('YYYY-MM-DD')) {
						if (latest == null || linkItem.expired_at > latest.expired_at) {
							latest = linkItem;
						}
					}
				});
				return latest;
			},
			publicLink(publicLinks) {
				return window.location.origin +'/'+ this.getLatestLink(publicLinks).slug;
			},
			makePublic(date)
			{
				this.saving = true;
				axios.post('/api/public-link', this.postData)
					.then((response) => {
						console.log(response.data);
						location.reload();
						this.saving = false;
					});
			},
			validExiparationDate(expirationDate) {
				let valid = false;
				if (moment(expirationDate).isValid() && moment(expirationDate).isAfter(moment().format('YYYY-MM-D'))) {
					valid = true;
				}
				return valid;
			},
			viewTemplate(gdriveId, templateId, advisorName) {
				this.loadingUrl = true;
				axios.get('/api/templates/'+templateId+'/view-as/'+gdriveId)
					.then((response) => {
						this.loadingUrl = false;
						this.searchedName = '';
						let template_id = response.data.template_id;
						let disclosure_id = response.data.disclosure_id;
						let logo_id = (response.data.logo_id ? response.data.logo_id : '');
						window.location.href = '/templates/'+template_id+'/'+disclosure_id+'/'+logo_id;
					})
					.catch((error)=> {
						this.loadingUrl = false;
						this.searchedName = '';
						this.urlErrorMsg = '* '+advisorName+' cannot use this template!';
					});
			}
		},
	}
</script>

<style lang="scss">
	
</style>