<template>
	<div class="header">
		<div class="container">
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="scss" scoped>
	.header {
		background: linear-gradient(-12deg, #943CF3, #3F88E7);
		padding: 40px 0;
		margin-bottom: 30px;
		margin-top: -22px;
		color: #fff;

		.title {
			font-weight: 700;
			margin-top: 20px;
			margin-bottom: 20px;
			// font-size: 38px;
			letter-spacing: 0.05em;
		}
	}
</style>