<template>
    <form @submit.prevent="submit">
        <div class="form-group">
            <label for="name">Name</label>
            <input v-model="name" type="text" name="name" class="form-control">
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input  v-model="email" type="email" name="email" class="form-control">
        </div>
        <div class="form-group">
            <h6>Is User Admin?</h6>
            <input type="radio" value="admin" v-model="role" />
            <label for="one">Yes</label>

            <input type="radio" :value="null" v-model="role" />
            <label for="two">No</label>
        </div>
        <input type="submit" value="Submit" class="btn btn-primary">
    </form>
</template>

<script>
    export default {
        data: () => ({
           name: '',
           email: '',
           role: null,
        }), 
        computed: {
            postData() {
                return {
                    'name': this.name,
                    'email': this.email,
                    'role': this.role,
                }
            }
        },
        methods: {
            submit() {
                axios.post('/users', this.postData)
                    .then((response) => {
                        window.location.href = '/users';
                    })
                    .catch((error) => {
                        console.log('Something is wrong!');
                    });
            }
        }
    }
</script>
