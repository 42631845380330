<template>
    <form @submit.prevent="submit">
        <div v-for="template in templates" :key="template.id">
            <input type="checkbox" name="templates[]" :value="template.id" v-model="formTemplates"> 
            <label>{{ template.name }}</label>
        </div>   
        <a href="/home" class="btn btn-primary">Back</a>
        <input type="submit" value="Save" class="btn btn-primary">
    </form>
</template>

<script>
    export default {
        props: ['company', 'templates'],
        data: () => ({
           formTemplates: [],
        }),
        computed: {
            postData() {
                return {
                    'templates': this.formTemplates,
                }
            } 
        },
        methods: {
            submit() {
                axios.post('/companies/'+ this.company.slug+'/templates', this.postData)
                    .then((response) => {
                        window.location.href = '/templates';
                    })
                    .catch((error) => {
                        console.log('Something is wrong!');
                    });
            }
        }, 
        created() {
            this.company.templates.forEach((template) => {
                this.formTemplates.push(template.id);
            });
        }
    }
</script>
