<template>
    <form @submit.prevent="submit">
        <input type="submit" value="Delete" class="dropdown-item">
    </form>
</template>
 
 <script>
    export default {
        props: ['user'],
        methods: {
            submit() {
                axios.delete('/users/' + this.user.id)
                    .then((response) => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log('Something is wrong!');
                    });
            }
        }
    }    
 </script>
 