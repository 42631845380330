<template>
    <form @submit.prevent="submit">
         <div class="form-group">
            <label for="gpsId">GPS ID</label>
            <input v-model="gpsId" type="number" name="gpsId" class="form-control">
        </div>
        <div class="form-group">
            <label for="name">Name</label>
            <input v-model="name" type="text" name="name" class="form-control">
        </div>
        <div class="form-group">
            <label for="slug">Slug</label>
            <input v-model="slug" type="text" name="slug" class="form-control">
        </div>
        <div class="form-group">
            <label for="view">View</label>
            <input v-model="view" type="text" name="view" class="form-control">
        </div>
        <input type="submit" value="Submit" class="btn btn-primary">
    </form>
</template>

<script>
    export default {
        data: () => ({
            gpsId: '',
            name: '',
            slug: '',
            view: '',
        }),
        computed: {
            postData() {
                return {
                    'gps_id': this.gpsId,
                    'name': this.name,
                    'slug': this.slug,
                    'view': this.view,
                }
            } 
        }, 
        methods: {
            submit() {
                axios.post('/templates', this.postData)
                    .then((response) => {
                        window.location.href = '/templates';
                    })
                    .catch((error) => {
                        console.log('Something is wrong!');
                    });
            }
        }          
    }
</script>
