/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('brobot', require('./components/Brobot.vue').default);
Vue.component('page-header', require('./components/Header.vue').default);

// Templates
Vue.component('template-index', require('./components/templates/Index.vue').default);
Vue.component('template-create', require('./components/templates/Create.vue').default);
Vue.component('template-edit', require('./components/templates/Edit.vue').default);

// Company Templates
Vue.component('company-template-index', require('./components/backend/company-template/Index.vue').default);

//Users
Vue.component('users-create', require('./components/backend/users/Create.vue').default);
Vue.component('users-edit', require('./components/backend/users/Edit.vue').default);
Vue.component('users-delete', require('./components/backend/users/Delete.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
