<template>
	<div>
		<!-- <p v-if="isAdmin" class="text-right"><a class="btn btn-primary" href="/templates/create">Add New Template</a></p> -->
		<div class="card">
			<div class="card-body px-0 pb-0">
				<ul class="nav border-bottom px-3 mb-4">
					<li class="nav-item">
						<a @click.prevent="showing = 'active'" class="nav-link" :class="[showing == 'active' ? 'border border-primary border-top-0 border-left-0 border-right-0' : 'text-body']" href="#">Active</a>
					</li>
					<li class="nav-item">
						<a @click.prevent="showing = 'pending-approval'" class="nav-link text-body" :class="[showing == 'pending-approval' ? 'border border-primary border-top-0 border-left-0 border-right-0' : 'text-body']" href="#">Pending Approval</a>
					</li>
					<li class="nav-item">
						<a @click.prevent="showing = 'archived'" class="nav-link" :class="[showing == 'archived' ? 'border border-primary border-top-0 border-left-0 border-right-0' : 'text-body']" href="#">Archived</a>
					</li>
					<li class="nav-item d-inline-block ml-auto">
						<input v-model="searchQuery" class="form-control" type="text" autofocus="autofocus" placeholder="Search templates..." style="width: 300px;">
					</li>
				</ul>
				<table v-if="filteredTemplates.length" class="table">
					<thead>
						<tr class="bg-light">
							<th class="pl-3">Template</th>
							<th>Companies</th>
							<th>GPS ID</th>
							<th>Version</th>
							<th>Revision Date</th>
							<th v-if="isAdmin" class="pr-3">Action</th>
						</tr>
					</thead>
					<tbody>
						<list-item v-for="template in filteredTemplates" 
							:template="template"
							:people="people"
							:key="template.id">
						</list-item>
					</tbody>
				</table>
				<h5 v-else class="text-center my-4">No Match Found</h5>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['templates', 'companies', 'people'],
		components: {
			'list-item': require('./ListItem').default,
		},
		data: () => ({
			searchQuery: '',
			showing: 'active',
		}),
		computed: {
			isAdmin() {
				return window.Laravel.user.role === 'admin';
			},
			filteredTemplates() {
				let filtered = [];
				switch(this.showing) {
					case 'active':
						filtered = this.templates.filter((template) => {
							return template.deleted_at == null;
						});
						break;
					case 'pending-approval':
						filtered = this.templates.filter((template) => {
							return template.deleted_at && template.archived_at == null;
						});
						break;
					case 'archived':
						filtered = this.templates.filter((template) => {
							return template.deleted_at && template.archived_at;
						});
						break;
				}

				return _.filter(filtered, (template) => {
					return this.searchQuery.toLowerCase().split(' ').every((item) => {
						return template.name.toLowerCase().includes(item) || template.gps_id.toString().includes(item); 
					}); 
				});
			},
			searchMatchEmpty() {
				return _.isEmpty(this.filteredTemplates)
			},
		},
	}
</script>